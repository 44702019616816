<template>
    <div class="myimgupload">
        <!-- [按钮]上传图片&文件-[前端->后端] -->
        <div v-if="myimgesup.uploadsize === 2">
            <el-upload
                class="upload-demo"
                :list-type="myimgesup.listtype"
                :show-file-list="myimgesup.showfilelist"
                :action="this.$baseurl + myimgesup.action + '?target_id=' + myimgesup.target_id"
                :headers="myimgesup.headers"
                :multiple="myimgesup.multiple"
                :data="myimgesup.data ? myimgesup.data : uploadParams"
                :file-list="filelist"
                :limit="myimgesup.limit"
                :on-exceed="(fileList, uploadFiles) => onExceedEvent(fileList, uploadFiles, myimgesup.limit)"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="(file) => beforeUpload(file, myimgesup.target_id, myimgesup.rules)"
                :on-progress="uploadChange"
                :on-success="uploadSuccess"
                :on-error="uploadError"
            >
                <el-button
                    :size="myimgesup.title_size ? myimgesup.title_size : 'small'"
                    :type="myimgesup.title_type ? myimgesup.title_type : 'primary'"
                    :plain="myimgesup.title_plain ? myimgesup.title_plain : false"
                    v-if="!myimgesup.isshow"
                    >{{ myimgesup.title }}
                </el-button>
                <template #tip v-if="myimgesup.tips">
                    <div style="min-height: 25px; line-height: 25px">
                        <span style="font-size: 12px; color: #999999; white-space: pre-wrap"> {{ myimgesup.tips }}</span>
                    </div>
                </template>
            </el-upload>
        </div>
        <!-- [按钮]上传图片&文件-[前端->ali-oss] -->
        <div v-if="myimgesup.uploadsize === 1">
            <el-upload
                class="upload-demo"
                :list-type="myimgesup.listtype"
                :show-file-list="myimgesup.showfilelist"
                action
                :multiple="myimgesup.multiple"
                :file-list="filelist"
                :limit="myimgesup.limit"
                :on-exceed="(fileList, uploadFiles) => onExceedEvent(fileList, uploadFiles, myimgesup.limit)"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="(file) => beforeUpload(file, myimgesup.target_id, myimgesup.rules)"
                :on-success="uploadSuccess"
                :http-request="(option) => handleUpload(option, this.myfilelist, myimgesup.target_id)"
                :on-change="onChangeEvent"
            >
                <el-button
                    :size="myimgesup.title_size ? myimgesup.title_size : 'small'"
                    :type="myimgesup.title_type ? myimgesup.title_type : 'primary'"
                    :plain="myimgesup.title_plain ? myimgesup.title_plain : false"
                    v-if="!myimgesup.isshow"
                    >{{ myimgesup.title }}
                </el-button>
                <template #tip v-if="myimgesup.tips">
                    <div style="min-height: 25px; line-height: 25px">
                        <span style="font-size: 12px; color: #999999; white-space: pre-wrap"> {{ myimgesup.tips }}</span>
                    </div>
                </template>
            </el-upload>
        </div>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
    name: 'Mainimgesup',
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            uploadParams: {},
            uploadHeader: { Authen: localStorage.getItem('token') },
            getwidthandheight: '',
            imgInfo: {},

            myfilelist: [] // filelist列表
        }
    },
    props: ['myimgesup', 'myimgesupisshow', 'filelist'],
    methods: {
        // ---------------------------------[按钮]上传图片&文件-[前端->后端]----------------------------------------------
        //点击图片filelist时，触发函数，且返回file数据
        handlePreview(file) {
            console.log(file)
        },
        handleExceed(files, fileList) {
            this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`)
        },
        // 上传文件超出数量限制时的函数
        onExceedEvent(file, fileList, limit) {
            console.log(file, fileList, limit)
            ElMessage({
                type: 'warning',
                message: '超出文件上传限制！'
            })
        },
        //上传图片-预览图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        // 上传文件-前端校验-判断
        filesUploadEvent(file, targetid, rules) {
            // 判断文件 - 格式&大小等
            let types = rules.files.type
            const isImage = types.includes(file.type)
            if (!isImage) {
                this.$message.error('上传文件只能是 text、pdf、word、xls等文件格式!')
                return false
            }
            const isLtSize = file.size / 1024 < rules.files.size
            if (!isLtSize) {
                this.$message.error('上传文件大小不能超过 ' + rules.files.size + ' KB!')
                return false
            }
            return isImage && isLtSize
        },
        // 上传video-前端校验-判断
        videoUploadEvent(file, targetid, rules) {
            // 判断文件 - 格式&大小等
            let types = rules.video.type
            const isImage = types.includes(file.type)
            if (!isImage) {
                this.$message.error('上传文件只能是 ' + rules.video.typechart + ' 文件格式!')
                return false
            }
            const isLtSize = file.size / 1024 < rules.video.size
            if (!isLtSize) {
                this.$message.error('上传文件大小不能超过 ' + rules.video.size + ' KB!')
                return false
            }
            return isImage && isLtSize
        },
        // 上传video-前端校验-判断
        audioUploadEvent(file, targetid, rules) {
            // 判断文件 - 格式&大小等
            let types = rules.audio.type
            const isImage = types.includes(file.type)
            if (!isImage) {
                this.$message.error('上传文件只能是 ' + rules.audio.typechart + ' 文件格式!')
                return false
            }
            const isLtSize = file.size / 1024 < rules.audio.size
            if (!isLtSize) {
                this.$message.error('上传文件大小不能超过 ' + rules.audio.size + ' KB!')
                return false
            }
            return isImage && isLtSize
        },
        // 上传images-前端校验-判断
        imagesUploadEvent(file, targetid, rules) {
            // 判断图片-后缀-兼容
            if (rules.images.suffix) {
                let suffix = rules.images.suffix
                let getsuffix = file.name.split('.')
                const isSuffix = suffix.includes(getsuffix[getsuffix.length - 1])
                if (!isSuffix) {
                    if (rules.images.typechart) {
                        this.$message.error('上传图片只能是 ' + rules.images.typechart + ' 图片格式!')
                    } else {
                        this.$message.error('上传图片只能是 JPG/JPEG/gif/bmp/PNG 图片格式!')
                    }
                    return false
                }
            }

            // 判断图片 - 格式&大小&图片宽高等
            let types = rules.images.type
            const isImage = types.includes(file.type)
            if (!isImage) {
                if (rules.images.typechart) {
                    this.$message.error('上传图片只能是 ' + rules.images.typechart + ' 图片格式!')
                } else {
                    this.$message.error('上传图片只能是 JPG/JPEG/gif/bmp/PNG  图片格式!')
                }
                return false
            }
            const isLtSize = file.size / 1024 < rules.images.size
            if (!isLtSize) {
                this.$message.error('上传图片大小不能超过 ' + rules.images.size + ' KB!')
                return false
            }
            if (rules.images.image_width != 0 && rules.images.image_height != 0) {
                const isSize = new Promise(function(resolve, reject) {
                    let width = rules.images.image_width
                    let height = rules.images.image_height
                    let _URL = window.URL || window.webkitURL
                    let image = new Image()
                    image.onload = function() {
                        let valid = image.width == width && image.height == height
                        valid ? resolve() : reject()
                    }
                    image.src = _URL.createObjectURL(file)
                }).then(
                    () => {
                        return file
                    },
                    () => {
                        this.$message.error('上传图片尺寸不符合,只能是' + rules.images.image_width + '*' + rules.images.image_height + '!')
                        return Promise.reject()
                    }
                )
                return isImage && isLtSize && isSize
            } else {
                return isImage && isLtSize
            }
        },
        //上传图片||文件-上传前（准备）-传参
        beforeUpload(file, targetid, rules) {
            // console.log("当前上传的文件targetid：" + targetid);
            // console.log("当前上传的文件类型：" + file.type);
            // 将文件类型返回给父组件
            this.$emit('getfiletype', file.type)

            if (rules) {
                if (rules.files && rules.files.type.includes(file.type)) {
                    return this.filesUploadEvent(file, targetid, rules)
                } else if (rules.video && rules.video.type.includes(file.type)) {
                    return this.videoUploadEvent(file, targetid, rules)
                } else if (rules.images && rules.images.type.includes(file.type)) {
                    return this.imagesUploadEvent(file, targetid, rules)
                } else if (rules.audio && rules.audio.type.includes(file.type)) {
                    return this.audioUploadEvent(file, targetid, rules)
                } else {
                    this.$message.error('该文件格式不正确!')
                    return false
                }
            } else {
                this.$message.error('无校验！！！')
                return false
            }
        },
        //移除文件前-触发的函数，可显示提示，待确定后移除文件
        beforeRemove(file) {
            // console.log("get file status is:" + file.status);
            if (file.status === 'success') {
                // 确定移除后，将数据回传至父组件-进行数据更新
                return this.$confirm(`确定移除该 ${file.name} 文件?`, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$emit('delfiledata', file)
                })
            } else {
                return
            }
        },
        //上传图片-删除图片
        handleRemove(file, fileList) {
            // console.log("删除图片||文件：" + file, fileList);
            this.$emit('deluploadfile', fileList)
        },
        //上传图片||文件-成功
        uploadSuccess(response, file, fileList) {
            console.log(response, file, fileList)
            if (response.code === 0) {
                //上传成功的文件后缀
                var successfile = file.name.split('.')
                // console.log(successfile);
                //上传成功的文件后缀名比较，且返回图片logo显示
                if (['jpg', 'jpeg', 'png', 'gif'].indexOf(successfile[successfile.length - 1]) === -1) {
                    //当文件非以上4中格式时，回显logo图片为以下图片(文件样式)
                    fileList[fileList.length - 1].url = require('@/assets/imgs/upload/upload.png')
                }
                //提示上传成功！
                ElMessage({
                    type: 'success',
                    message: '上传成功！'
                })
                //上传成功后，将图片||文件地址，返回父组件
                response.result.name = successfile[0]
                response.result.type = successfile[successfile.length - 1] //返回文件后缀-判断类型
                response.result.status = 1 //返回文件后缀-判断类型
                // 将上传成功的文件数据返回给父组件
                this.$emit('getuploadfile', response.result)
                // this.$emit("getfiledatas",file)
                // this.$emit("getfilelistdatas",fileList)
            } else {
                //提示上传失败！
                ElMessage({
                    type: 'error',
                    message: '上传失败！' + response.message
                })
                //删除当前队列中的最后一个
                fileList.splice(fileList.length - 1, 1)
            }
            // console.log("上传成功返回数据！" + response, file, fileList);
        },
        //上传图片&文件-失败
        uploadError(err, file, fileList) {
            //提示上传失败！
            ElMessage({
                type: 'error',
                message: '上传失败！'
            })
            console.log('上传失败！' + err, file, fileList)
        },
        // ---------------------------------------------------------------------------------------------------------------

        // ---------------------------------[按钮]上传图片&文件-[前端->ali-oss]---------------------------------------------
        // 生成随机文件名称-规则八位随机字符，加下划线连接时间戳
        getFileNameUUID() {
            function rx() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
            }
            return `${+new Date()}_${rx()}${rx()}`
        },
        // alioss上传文件-alioss限制最大不能超过5GB
        async put(ObjName, fileUrl) {
            // [按钮]上传图片&文件-[前端->ali-oss]
            let OSS = require('ali-oss')
            let client = new OSS({
                region: 'oss-cn-beijing',
                secure: true, // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问
                accessKeyId: 'LTAI5tDPywVR9EPSh2yoyRP7',
                accessKeySecret: 'b8pkJrJuMlHODknDx0Omxct6FVdxch',
                bucket: this.$bucketurl // 阿里云中bucket存储空间类似于存储文件夹，可在其中存放多个文件夹
            })

            try {
                // let result = await client.put(`${ObjName}`, fileUrl, { headers: { "Content-Disposition": "inline"}});
                let result = await client.put(`${ObjName}`, fileUrl)
                return result
            } catch (e) {
                console.log(e)
            }
        },
        // alioss上传成功之后，转换真实的地址
        async signatureUrl(ObjName) {
            // [按钮]上传图片&文件-[前端->ali-oss]
            let OSS = require('ali-oss')
            let client = new OSS({
                region: 'oss-cn-beijing',
                secure: true, // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问
                accessKeyId: 'LTAI5tDPywVR9EPSh2yoyRP7',
                accessKeySecret: 'b8pkJrJuMlHODknDx0Omxct6FVdxch',
                bucket: this.$bucketurl // 阿里云中bucket存储空间类似于存储文件夹，可在其中存放多个文件夹
            })

            try {
                let result = await client.signatureUrl(`${ObjName}`)
                return result
            } catch (e) {
                console.log(e)
            }
        },
        // 自定义上传方法
        handleUpload(option, fileList, targetid) {
            console.log(option, fileList)
            // 获取真实文件名称
            var trueName = option.file.name
            // 获取文件后缀格式
            var gettype = trueName.split('.')
            var lasttype = gettype[gettype.length - 1]
            // 获取文件的后缀名
            var obj = option.file.name
            var index = obj.lastIndexOf('.')
            obj = obj.substring(index, obj.length)
            console.log(obj)
            // 使用原始名称-上传
            // let objName = option.file.name;
            // 组合随机名称-上传
            let objName = this.getFileNameUUID() + obj

            let filename = this.$bucketfile
            let upFileName = filename + 'images'
            if (targetid == 1) {
                // 二维码
                upFileName = filename + 'qrcode'
            } else {
                if (targetid == 3) {
                    // 文库文件
                    upFileName = filename + 'print_source'
                } else {
                    if (targetid == 4) {
                        // 课程文件
                        upFileName = filename + 'print_course'
                    } else {
                        if (targetid == 5) {
                            // 广告主宣传页
                            upFileName = filename + 'advertiser_extend'
                        } else {
                            if (targetid == 6) {
                                // 广告主产生的PDF文件
                                upFileName = filename + 'advertiser'
                            } else {
                                if (targetid == 7 || targetid == 8 || targetid == 100 || targetid == 101) {
                                    // 7证件照 8常见问题图标 100通用 101设置
                                    upFileName = filename + 'general'
                                } else {
                                    if (targetid == 50) {
                                        // 设备广告
                                        upFileName = filename + 'client_advert'
                                    } else {
                                        if (targetid == 51) {
                                            // 设备广告申诉
                                            upFileName = filename + 'client_advert_appeal'
                                        } else {
                                            if (targetid == 9) {
                                                // 退款
                                                upFileName = filename + 'share_question'
                                            } else {
                                                if (targetid == 10) {
                                                    // 退款审核
                                                    upFileName = filename + 'share_question_audits'
                                                } else {
                                                    if (targetid == 11) {
                                                        // 提现发票
                                                        upFileName = filename + 'widthdraw_fapiao'
                                                    } else {
                                                        if (targetid == 12) {
                                                            // (未知)
                                                            upFileName = filename + 'printcate/static'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
            this.put(`${upFileName}/${objName}`, option.file).then((res) => {
                // console.log(res);
                var that = this
                let getdatas = res

                //上传成功的文件后缀名比较，且返回图片logo显示
                if (['jpg', 'jpeg', 'png', 'gif'].indexOf(lasttype) == -1) {
                    if (['doc', 'docx', 'ppt', 'pptx', 'pdf', 'xls', 'xlsx'].indexOf(lasttype) == -1) {
                        // 【视频||音频】
                        //当文件非以上4中格式时，回显logo图片为以下图片(文件样式)
                        getdatas.url = require('@/assets/imgs/upload/upload.png')

                        //获取视频或者音频时长
                        var fileurl = getdatas.res.requestUrls[0]
                        var audioElement = new Audio(fileurl)
                        var fileLen = ''
                        audioElement.addEventListener('loadedmetadata', function(_event) {
                            console.log(_event)
                            var duration = audioElement.duration
                            fileLen = Math.floor(duration)
                            console.log(fileLen)

                            //提示上传成功！
                            ElMessage({
                                type: 'success',
                                message: '上传成功！'
                            })
                            // 更新filelist
                            fileList[fileList.length - 1] = {
                                name: trueName,
                                status: 'success',
                                url: getdatas.url
                            }
                            //上传成功后，将图片||文件地址，返回父组件
                            getdatas.name = trueName
                            getdatas.type = lasttype //返回文件后缀-判断类型
                            getdatas.status = 1 //返回文件-状态-成功为1
                            getdatas.filelist = fileList //返回-成功的filelist
                            getdatas.fileLen = fileLen // 返回-视频||音频时长
                            // 将上传成功的文件数据返回给父组件
                            that.$emit('getuploadfile', getdatas)
                        })
                    } else {
                        // 【word等office文件相关】
                        //提示上传成功！
                        ElMessage({
                            type: 'success',
                            message: '上传成功！'
                        })
                        // 更新filelist
                        fileList[fileList.length - 1] = {
                            name: trueName,
                            status: 'success',
                            url: getdatas.url
                        }
                        //上传成功后，将图片||文件地址，返回父组件
                        getdatas.name = trueName
                        getdatas.type = lasttype //返回文件后缀-判断类型
                        getdatas.status = 1 //返回文件-状态-成功为1
                        getdatas.filelist = fileList //返回-成功的filelist
                        // 将上传成功的文件数据返回给父组件
                        that.$emit('getuploadfile', getdatas)
                    }
                } else {
                    // 【图片相关】
                    //提示上传成功！
                    ElMessage({
                        type: 'success',
                        message: '上传成功！'
                    })
                    // 更新filelist
                    fileList[fileList.length - 1] = {
                        name: trueName,
                        status: 'success',
                        url: getdatas.url
                    }
                    //上传成功后，将图片||文件地址，返回父组件
                    getdatas.name = trueName
                    getdatas.type = lasttype //返回文件后缀-判断类型
                    getdatas.status = 1 //返回文件-状态-成功为1
                    getdatas.filelist = fileList //返回-成功的filelist
                    // 将上传成功的文件数据返回给父组件
                    that.$emit('getuploadfile', getdatas)
                }

                // 上传成功之后，转换真实的地址-用于图片下载
                this.signatureUrl(`${upFileName}/${objName}`).then((res) => {
                    console.log(res)
                    // window.open(res) //下载图片
                })
            })
        },

        // 上传文件改变状态时触发事件
        onChangeEvent(file, fileList) {
            // console.log(file, fileList);
            this.myfilelist = fileList
        }

        // ---------------------------------------------------------------------------------------------------------------
    },
    components: {}
}
</script>

<style scoped></style>
